import React, { useState } from "react";
import logo from "./logo.svg";
import "./styles/main.scss";

import { QueryClient, QueryClientProvider } from "react-query";
import { getTokenAsync } from "./firebaseInit";
import ChatUsers from "./components/chatUsers";
import Login from "./components/login";
import Header from "./components/header";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Layout from "./components/layout";
function App() {
  const queryClient = new QueryClient();

  getTokenAsync();

 

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
          <Switch>
            <Route path="/login" render={(props: any) => <Login {...props} />} />
            <Route path="/" render={(props: any) => <Layout {...props} />} />
          </Switch>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
