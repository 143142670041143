import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { FetchSpaces } from "../../services/spaces";

const Spaces = ({setSpace}:any) => {
    const [Selected, setSelected] = useState(0);
    useEffect(() => {
        setSpace(Selected)
    }, [Selected])
  const { data, status, isLoading } = useQuery(
    "Spaces",
    async () => FetchSpaces(),
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );
  return (
    <div className="spacesCon">
        <div onClick={()=>setSelected(0)} className={Selected===0?"space active":"space"}>
          <div className="imageCon">
            <img src="" alt="" />
          </div>
          <div className="spaceTitle">All</div>
        </div>
      {data?.json?.map((space: any) => (
        <div onClick={()=>setSelected(space.id)} className={Selected===space.id?"space active":"space"}>
          <div className="imageCon">
            <img src={space.icon.url} alt="" />
          </div>
          <div className="spaceTitle">{space.name}</div>
        </div>
      ))}

    </div>
  );
};

export default Spaces;
