import React from "react";
import { FetchAudio } from "../../../services/chats";
import { useQuery } from "react-query";
import { Col, Row } from "antd";

const Audio = ({ RoomId }: any) => {
  const { data, status, isLoading } = useQuery(
    ["Audio",RoomId],
    async () => FetchAudio(RoomId),
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );
  return (
    <div className="audio">
      <Row gutter={[15, 15]}>
        {data?.json?.data?.map((audio: any) => (
          <Col span={24}>
           <audio src={audio?.url} controls />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Audio;
