import { AxiosRequestConfig } from "axios";
import ApiService from "../../utils/api/api-service";
import ApiResponse, { isError } from "../../utils/api/models/api-response";
import { ApiErrorNotification } from "../../utils/ui/notificationService";
import { useQueryClient } from "react-query";

class SpaceService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: "https://cascade.kodiee.online/public/api/",
      ...config,
    });
  }

  public async Fetch(): Promise<ApiResponse<any>> {
    return this.get(`/space/all`);
  }
}

const spaceService = new SpaceService();

export const FetchSpaces = async () => {
  const result: any = await spaceService.Fetch();
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
