import React from "react";
import { FetchMember } from "../../../services/chats";
import { useQuery } from "react-query";

const Participants = ({ RoomId }: any) => {
  const { data, status, isLoading } = useQuery(
    ["member",RoomId],
    async () => FetchMember(RoomId),
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );
  return (
    <div className="participants">
      <div className="chatUsers">
        {data?.json &&
          data?.json?.members.map((user: any, index: number) => (
            <div className={"userCard"}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="cardImgCon">
                  <div className="imgPlaceHolder">
                    <img src={user?.profile_media?.url_path} alt="" />
                  </div>
                </div>
                <div className="cardText">
                  <div className="cardTitle">{user?.full_name}</div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Participants;
