import React from 'react';
import { FetchMedia } from '../../../services/chats';
import { useQuery } from 'react-query';
import { Col, Image, Row } from 'antd';

const Media = ({RoomId}:any) => {
    const { data, status, isLoading } = useQuery(
        ["Media",RoomId],
        async () => FetchMedia(RoomId),
        {
          cacheTime: 0,
          staleTime: Infinity,
        }
      );
    return (
        <div className='media'>
            <Row gutter={[15,15]}>
            {data?.json?.data?.map((image:any)=>(
                    <Col span={8}>
                        <Image src={image.url}/>
                    </Col>
            ))}
            </Row>
        </div>
    );
};

export default Media;