import { Col, Input, Modal, Row, Tabs, TabsProps } from "antd";
import React, { useEffect, useState } from "react";
import ChatUsers from "../../chatUsers";
import { useInfiniteQuery, useQuery } from "react-query";
import { FetchGroups } from "../../../services/groups";
import ChatRoom from "../../chatRoom";
import Participants from "../../groupInfo/participants";
import Media from "../../groupInfo/media";
import Files from "../../groupInfo/files";
import Audio from "../../groupInfo/audio";
import Spaces from "../../spaces";
import { FetchMessages } from "../../../services/chats";

const { Search } = Input;

const Groups = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ModalSearch, setModalSearch] = useState(false);
  const [RoomId, setRoomId] = useState(0);
  const [SubRoomId, setSubRoomId] = useState(0);
  const [user, setUser] = useState<any>({});
  const [space, setSpace] = useState<any>(0);
  const [UserLoc, setUserLoc] = useState<any>({});
  const [UserLoading, setUsersLoading] = useState<any>(true);
  const [SearchText, setSearch] = useState<any>("");
  const [SearchMessage, setSearchMessage] = useState<any>("");
  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
  }, []);
  useEffect(() => {
    if (SearchText !== "") setUsersLoading(true);
  }, [SearchText]);
  useEffect(() => {
    setUsersLoading(true);
  }, [space]);

  const { data, status, isLoading } = useQuery(
    ["groups", space, SearchText],
    async () => FetchGroups(1, space, SearchText),
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );
  useEffect(() => {
    console.log(data);
    if (data?.json && data?.json.length > 0) {
      setRoomId(data?.json[0].id);
      setUser(data?.json[0]);
      if (data?.json[0]?.staff_chat !== undefined)
        setSubRoomId(data?.json[0]?.staff_chat?.id);
      else setSubRoomId(0);
      setUsersLoading(false);
    }
  }, [data]);
  const items: TabsProps["items"] = [
    {
      key: "1",
      disabled: user?.props?.find((o: any) => o.perm === 1).allow === 3,
      label: "Participants",
      children: <Participants RoomId={RoomId} />,
    },
    {
      key: "2",
      label: "Media",
      children: <Media RoomId={RoomId} />,
    },
    {
      key: "3",
      label: "Files",
      children: <Files RoomId={RoomId} />,
    },
    {
      key: "4",
      label: "Audio",
      children: <Audio RoomId={RoomId} />,
    },
  ];

  const searchMessage: any = useInfiniteQuery(
    ["MessagesSearch", SearchMessage, RoomId],
    async ({ pageParam = 1 }) =>
      FetchMessages(RoomId, pageParam, 0, SearchMessage),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => false,
      cacheTime: 0,
      staleTime: Infinity,
      refetchInterval: 10000,
      enabled: RoomId !== 0 && SearchMessage !== "",
    }
  );
  return (
    <div className="chat">
      <Row gutter={[25, 0]}>
        <Col span={24}>
          <Spaces setSpace={(space: any) => setSpace(space)} />
        </Col>
        <Col span={8}>
          <ChatUsers
            loading={UserLoading}
            data={data?.json}
            RoomId={RoomId}
            setRoomId={(Id: any) => {
              setRoomId(Id);
            }}
            setSubRoomId={(Id: any) => setSubRoomId(Id)}
            setUser={(data: any) => {
              setUser(data);
            }}
            setSearch={(data: any) => {
              setSearch(data);
            }}
          />
        </Col>
        <Col span={16}>
          <button onClick={() => setIsModalOpen(true)} className="infoBtn">
            <img src="/images/info.svg" alt="" />
          </button>
          <button onClick={() => setModalSearch(true)} className="SearchBtn">
            <img src="/images/magnifying-glass.png" alt="" />
          </button>
          <Modal
            className="groupModal"
            open={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            footer={[]}
          >
            <img src={user?.icon?.url} alt="" className="groupImg" />
            <div className="groupName">{user?.name}</div>
            {user?.description !== null && (
              <div className="groupDes">{user?.description}</div>
            )}
            <Tabs
              defaultActiveKey={
                user?.props?.find((o: any) => o.perm === 1).allow === 1
                  ? "2"
                  : "1"
              }
              items={items}
            />
          </Modal>
          <Modal
            className="groupModal"
            open={ModalSearch}
            onCancel={() => setModalSearch(false)}
            footer={[]}
          >
            <Search
              placeholder={"Seach for message in " + user.name + " group"}
              onSearch={(e: any) => setSearchMessage(e)}
              onChange={(e: any) =>
                e.target.value === "" && setSearchMessage("")
              }
            />
            <div className="searchMessageCon">
              {searchMessage?.data?.pages[0]?.json.length === 0 && (
                <div className="notFound">No Result found</div>
              )}
              {searchMessage?.data?.pages[0]?.json.map((searchMsg: any) => (
                <div className="searchMessage">
                  <div className="searchUser">
                    <img src={searchMsg.user?.profile_image?.url} alt="" />
                    {searchMsg?.user?.full_name}
                  </div>
                  <div className="searchMessageText">{searchMsg?.text}</div>
                  <div className="messageDate">
                    {searchMsg?.created_at.split("T")[0]}
                  </div>
                </div>
              ))}
            </div>
          </Modal>
          {SubRoomId !== 0 && UserLoc !== "client" ? (
            <Tabs
              defaultActiveKey="1"
              items={[
                {
                  key: "1",
                  label: "Clients",
                  children: (
                    <ChatRoom
                      group
                      user={user}
                      roomId={RoomId}
                      setRoomId={(Id: any) => setRoomId(Id)}
                    />
                  ),
                },
                {
                  key: "2",
                  label: "Staff",
                  children: (
                    <ChatRoom
                      group
                      user={user}
                      roomId={SubRoomId}
                      setRoomId={(Id: any) => setRoomId(Id)}
                    />
                  ),
                },
              ]}
            />
          ) : (
            <ChatRoom
              group
              SubRoomId={SubRoomId}
              user={user}
              roomId={RoomId}
              setRoomId={(Id: any) => setRoomId(Id)}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Groups;
