import { Avatar, Input, List, Skeleton } from "antd";
import React from "react";
import { useQuery } from "react-query";

const { Search } = Input;

const ChatUsers = ({
  setRoomId,
  setUser,
  data,
  RoomId,
  setSubRoomId,
  forward,
  loading,
  setSearch,
}: any) => {
  return (
    <div className="chatUsers">
        <Search
            placeholder="Seach"
            onSearch={(e: any) => setSearch(e)}
            onChange={(e)=>e.target.value===""&&setSearch("")}
          />
      {loading ? (
        <div style={{ padding: " 30px 30px" }}>
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
        </div>
      ) : (
        <>
        
          {data &&
            data.map((user: any, index: number) => (
              <div
                onClick={() => {
                  if (!forward) setRoomId(user.id);
                  setUser(user);
                  if (!forward) {
                    if (user?.staff_chat !== undefined)
                      setSubRoomId(user?.staff_chat?.id);
                    else setSubRoomId(0);
                  }
                }}
                className={RoomId === user.id ? "userCard active" : "userCard"}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="cardImgCon">
                    <div className="imgPlaceHolder">
                      <img src={user?.icon?.url} alt="" />
                    </div>
                  </div>
                  <div className="cardText">
                    <div className="cardTitle">{user?.name}</div>
                    <div className="cardDes">
                      {!forward && user?.last_message?.content}
                    </div>
                  </div>
                </div>
                <div className="cardDetails">
                  <div className="cardTime">
                    {!forward && user?.created_at?.split("T")[0]}
                  </div>
                  {!forward && user?.unread_messages_count !== 0 && (
                    <div className="messageCount">
                      {user?.unread_messages_count}
                    </div>
                  )}
                </div>
              </div>
            ))}
        </>
      )}
    </div>
  );
};

export default ChatUsers;
