import { notification } from "antd";
import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { useQuery, useQueryClient } from "react-query";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyCGUFMkYjyDglo9FrwPPvNiViwD6_BQYe0",
  authDomain: "montana-826a3.firebaseapp.com",
  databaseURL: "https://montana-826a3-default-rtdb.firebaseio.com",
  projectId: "montana-826a3",
  storageBucket: "montana-826a3.appspot.com",
  messagingSenderId: "595728121190",
  appId: "1:595728121190:web:e48e6a3c1426f72b3fdca7",
  measurementId: "G-1CC09XE70T"
});
if (firebaseApp) {
}

export const messaging = getMessaging(firebaseApp);

export const getTokenAsync = () => {
  return getToken(messaging, {
    vapidKey:
      "BMEj4K9MMjjTAn2elTXUQUQLqBVg25m4WNRw8BQky_2KTGVUROOdYa5ML6V5bVP_OmHuTxH369GMvTGo5JYpxFs",
  })
    .then((currentToken) => {
      if (currentToken) {

        console.log(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
        if (localStorage.getItem("ADMIN_TOKEN") !== null)
          axios.post(
            process.env.REACT_APP_API_URL + "api/add-firebase-token",
            {
              firebase_token: currentToken,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("ADMIN_TOKEN")}`,
              },
            }
          );
        return currentToken;
      }
    })
    .catch((err) => {
      console.log("Error firebase", err);
      // catch error while creating clieernt token
    });
};
