import React, { useEffect, useState } from "react";

import { Button, Layout, Menu } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { logout } from "../../actions/auth";
const HeaderAntd = Layout.Header;

const Header = () => {
  const history = useHistory();
  const location = useLocation();
  const [User, setUser] = useState<any>({});
 
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUser(t);
  }, []);

  return (
    <header>
      <HeaderAntd style={{ display: "flex", alignItems: "center" }}>
        <img src="/images/chat-smile.png" alt="" className="logo" />

        <Menu
          selectedKeys={[location.pathname.split("/")[1]]}
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["groups"]}
        >
          <Menu.Item
            key={"groups"}
            onClick={() =>
              history.push({
                pathname: "/groups",
              })
            }
          >
            Groups
          </Menu.Item>
          <Menu.Item
            key={"private"}
            onClick={() =>
              history.push({
                pathname: "/private",
              })
            }
          >
            Private Chats
          </Menu.Item>
        </Menu>
        <div className="username">
          <img src={User?.icon?.url} alt="" />
          {User?.username}
        </div>
        <Button
          onClick={() => {
            setLoading(true);
            logout()
              .then(() => history.push("/login"))
              .catch(() => history.push("/login"));
          }}
          loading={Loading}
          className="logoutBtn"
          type="primary"
        >
          Logout
        </Button>
      </HeaderAntd>
    </header>
  );
};

export default Header;
