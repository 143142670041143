import { AxiosRequestConfig } from "axios";
import ApiService from "../../utils/api/api-service";
import ApiResponse, { isError } from "../../utils/api/models/api-response";
import { ApiErrorNotification } from "../../utils/ui/notificationService";
import { useQueryClient } from "react-query";

class GroupsService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: "https://cascade.kodiee.online/public/api/",
      ...config,
    });
  }

  public async Fetch(page: number): Promise<ApiResponse<any>> {
    return this.get(`/get-messages?page=${page}`);
  }
  public async FetchGroups(
    page: number,
    space: any,
    search:string
  ): Promise<ApiResponse<any>> {
    return this.get(
      `/chat/group/all?page=${page}${space !== 0 ? "&space_ids[]=" + space+"&parent_only=1" : ""}${search!==""?"&criteria="+search:""}`
    );
  }
}

const groupsService = new GroupsService();

export const FetchMessages = async (page: number) => {
  const result: any = await groupsService.Fetch(page);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
export const FetchGroups = async (page: number, space: any,search:string) => {
  const result: any = await groupsService.FetchGroups(page, space,search);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
