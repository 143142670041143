import { Button, Form, Input, notification } from "antd";
import React, { useEffect, useState } from "react";
import { login } from "../../actions/auth";
import { useHistory } from "react-router-dom";

const Login = () => {
  const history = useHistory();
  const [Loading, setLoading] = useState(false);
  const submitLogin = (e: any) => {
    setLoading(true);
    login(e.username, e.password)
      .then(() => {
        history.push("/");
      })
      .catch((error) => {
        setLoading(false);
        notification.open({
          message: "Error",
          description: error.message,
        });
      });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.push("/");
    }
  }, []);

  return (
    <div className="login">
      <div className="loginBox">
        <div className="logoCon">
        <img src="/images/chat-smile.png" alt="" className="logo" />
        <div className="logoText">Cascade</div>
        </div>
        <Form
          onFinish={(e) => submitLogin(e)}
          name="loginForm"
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input autoComplete={"new-username"}  />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
             autoComplete="new-password"
            />
          </Form.Item>

          <Form.Item>
            <Button loading={Loading} type="primary" htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
