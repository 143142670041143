import { notification } from "antd";
import axios from "axios";

// import { DeviceUUID } from "device-uuid";
import { getTokenAsync } from "../firebaseInit";

const API_URL = process.env.REACT_APP_API_URL;

const login = (username: any, password: string) => {
  return axios
    .post("https://cascade.kodiee.online/public/api/" + "auth/login", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.json.token) {
        localStorage.setItem("token", response.data.json.token);
        localStorage.setItem("user", JSON.stringify(response.data.json));
      }
      getTokenAsync();
      return response.data;
    });
};
const logout = () => {  
  let config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  return axios
    .post(
      "https://cascade.kodiee.online/public/api/" + "auth/logout",
      {  _method: "post" },
      { ...config }
    )
    .then((response) => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");

      return response;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        return true;
      } else {
        notification.open({
          type: "error",
          message: error.message,
          duration: 300,
        });
      }
    });
};

export default {
  login,
  logout,
};
